










/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component({
  components: {}
})
export default class Success extends Vue {
  private customerId!: string | (string | null)[];
  private sessionId: string | (string | null)[] = this.$route.query.session_id

  async mounted (): Promise<void> {
    if (this.sessionId) {
      try {
        const { data } = await axios.get(`http://localhost:4200/checkout/checkout-session?sessionId=${this.sessionId}`)
        // We store the customer ID here so that we can pass to the
        // server and redirect to customer portal. Note that, in practice
        // this ID should be stored in your database when you receive
        // the checkout.session.completed event. This demo does not have
        // a database, so this is the workaround. This is *not* secure.
        // You should use the Stripe Customer ID from the authenticated
        // user on the server.
        this.customerId = data.customer
        console.log(this.customerId)

        const sessionJSON = JSON.stringify(data, null, 2)
        console.log('sessionjson:', sessionJSON)
        document.querySelector('pre')!.textContent = sessionJSON
      } catch (err) {
        console.error('Error when fetching Checkout session', err)
      }
    }
  }

  // In production, this should check CSRF, and not pass the session ID.
  // The customer ID for the portal should be pulled from the
  // authenticated user on the server.
  async redirectBilling () {
    try {
      window.alert(this.sessionId)
      const { data } = await axios.post('http://localhost:4200/checkout/customer-portal', { customerId: this.customerId })
      this.$router.push(data.url)
    } catch (err) {
      console.error('Error:', err)
    }
  }
}
